import React from 'react';
import {
  ArrowRightIcon, CodeIcon, DatabaseIcon, PresentationChartBarIcon,
} from '@heroicons/react/outline';

import {useAnalytics} from '../hooks';
import {
  ChipIcon,
  ClipboardIcon,
  PuzzleIcon,
  UsersIcon,
  CogIcon,
} from './svg';

const TrackCard = ({
  icon, name, link, onClick,
}) => (
  <a
    className="p-6 bg-white hover:bg-gray-50 rounded-xl bg-white border border-gray-300 hover:shadow-sm transition duration-50 flex flex-col flex-shrink-0 items-center justify-start w-32 lg:w-full"
    href={link}
    onClick={onClick}
  >
    <span className="text-indigo">{icon}</span>
    <span className="mt-2 font-medium text-base text-gray-800 text-center leading-tight">{name}</span>
  </a>
);

const ViewAllCard = ({
  icon, name, link, onClick,
}) => (
  <a
    className="py-6 px-2 bg-white rounded-xl bg-white hover:bg-gray-50 border-gray-300 hover:shadow-sm transition duration-50 flex flex-col flex-shrink-0 items-center justify-center w-32 lg:w-full"
    href={link}
    onClick={onClick}
  >
    <span className="text-gray-500">{icon}</span>
    <span className="mt-2 font-medium text-base text-gray-600 text-center leading-tight">{name}</span>
  </a>
);

export const TracksOverview = () => {
  const {tracker} = useAnalytics();

  return (
    <div className="py-6">
      <div className="">
        <div className="px-4 pb-2 flex flex-row lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-8 space-x-3 lg:space-x-0 lg:gap-x-5 gap-y-5 overflow-scroll scrollbar-hidden">
          <TrackCard
            icon={<ClipboardIcon />}
            name="Product Management"
            link="/courses/pm"
            onClick={() => tracker.landingClickTrack({course: 'pm'})}
          />
          <TrackCard
            icon={<UsersIcon />}
            name="Engineering Management"
            link="/courses/engineering-management"
            onClick={() => tracker.landingClickTrack({course: 'engineering-management'})}
          />
          <TrackCard
            icon={<CodeIcon className="h-8 w-8" />}
            name="Software Engineering"
            link="/courses/software-engineering"
            onClick={() => tracker.landingClickTrack({course: 'swe'})}
          />
          <TrackCard
            icon={<DatabaseIcon className="h-8 w-8" />}
            name="Data Engineering"
            link="/courses/data-engineering"
            onClick={() => tracker.landingClickTrack({course: 'data-engineering'})}
          />
          <TrackCard
            icon={<PresentationChartBarIcon className="h-8 w-8" />}
            name="Data Science"
            link="/courses/data-science"
            onClick={() => tracker.landingClickTrack({course: 'data-science'})}
          />
          <TrackCard
            icon={<ChipIcon />}
            name="Machine Learning"
            link="/courses/ml-engineer"
            onClick={() => tracker.landingClickTrack({course: 'ml-engineer'})}
          />
          <TrackCard
            icon={<CogIcon />}
            name="TPM"
            link="/courses/tpm"
            onClick={() => tracker.landingClickTrack({course: 'tpm'})}
          />
          <ViewAllCard
            icon={<ArrowRightIcon className="h-6 w-6" />}
            name="View all roles"
            link="/courses?src=homepage"
            onClick={() => tracker.landingClickTrack({course: 'all'})}
          />
        </div>
      </div>
    </div>
  );
};

export default TracksOverview;
